<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn class="ml-2" icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rounded-lg px-3 py-8">
                <p class=" text-center">
                  <v-chip :color="colors[subject.subject_id % 12]" outlined>
                    <v-icon left>
                      mdi-cube-outline
                    </v-icon>
                    <p class="mb-0 font-weight-bold">
                      {{ subject.speciality }}
                    </p>
                  </v-chip>
                </p>
                <p class="mb-0 text-h5 font-weight-bold text-center">
                  {{ subject.grade }} -
                  <span class="text-lowercase">{{ selectedStage?.name }}</span>
                </p>
                <p class="text-h5 mb-0 text-center">
                  <span class="font-weight-medium">{{ subject?.name }}</span>
                </p>
                <p class="text-h6 mb-0 text-center">
                  <span class="font-weight-medium"> ({{ subject?.code }})</span>
                  -
                  <span class="font-weight-normal">{{
                    subject.subject_evaluation_mehtodology
                  }}</span>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="8" class="mx-auto">
              <template v-if="scores.length > 0">
                <v-expansion-panels>
                  <v-expansion-panel
                    class="rounded-lg my-2 elevation-1"
                    v-for="(item, index) in scores"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      <p class="mb-0">
                        <v-icon class="mr-2" :color="colors[index % 12]">
                          mdi-account-supervisor-circle
                        </v-icon>
                        <v-chip
                          :color="sectionColors[`${item.section}`]"
                          outlined
                        >
                          <p class="mb-0 font-weight-bold">
                            Sección {{ item.section }}-{{ item.group }}
                          </p>
                        </v-chip>
                      </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="headers"
                        :items="item.students"
                        :items-per-page="15"
                        class="elevation-0 mx-md-5"
                      >
                        <template v-slot:[`item.code`]="{ item }">
                          <template>
                            <p class="font-weight-bold text-h6">
                              {{ item.code }}
                            </p>
                          </template>
                        </template>
                        <template v-slot:[`item.first_name`]="{ item }">
                          <template>
                            <p class="font-weight-medium text-body-1">
                              {{ item.first_name }}
                            </p>
                          </template>
                        </template>
                        <template v-slot:[`item.last_name`]="{ item }">
                          <template>
                            <p class="font-weight-medium text-body-1">
                              {{ item.last_name }}
                            </p>
                          </template>
                        </template>
                        <template v-slot:[`item.score`]="{ item }">
                          <template>
                            <p class="font-weight-bold text-body-1">
                              {{ item.score }}
                            </p>
                          </template>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <template v-else>
                <v-card class="pa-10" outlined>
                  <p class="mb-0 text-body-1 font-weight-bold text-center">
                    No se encontraron estudiantes en el grupo seleccionado.
                  </p>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "TechnicalStageAverageScores",
  components: {},
  data() {
    return {
      sectionColors: {
        A: "light-blue darken-2",
        B: "teal darken-2",
        C: "green darken-2",
        D: "purple darken-2",
        E: "indigo darken-4",
      },
      dialog: false,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "code",
        },
        { text: "Apellido", value: "last_name", sortable: true },
        { text: "Nombre", value: "first_name", sortable: true },
        { text: "Nivel de logro", value: "score", sortable: true },
      ],
    };
  },
  props: {
    subject: {
      type: Object,
    },
    scores: {
      type: Array,
    },
    selectedGroup: {
      type: Object,
    },
    selectedStage: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    closeModal() {
      this.toggleModal();
    },
  },
  computed: {
    grade() {
      return this.selectedGroup?.group?.substring(
        0,
        this.selectedGroup?.group?.length - 14
      );
    },
    section() {
      return this.selectedGroup?.group?.substring(
        this.selectedGroup?.group?.length - 3,
        this.selectedGroup?.group?.length - 2
      );
    },
    group() {
      return this.selectedGroup?.group?.substring(
        this.selectedGroup?.group?.length - 1
      );
    },
  },
};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
