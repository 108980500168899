<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Mis asignaturas
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar todas las asignaturas que
              impartes.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="subjects.search"
                  ></v-text-field>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="grades.isLoading ? 'Cargando grados' : 'Grado'"
                      :loading="grades.isLoading"
                      :disabled="!grades.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="grades.data"
                      item-text="name"
                      item-value="id"
                      v-model="subjects.filters.grade_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ subjects.filters.grade_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        specialities.isLoading
                          ? 'Cargando especialidades'
                          : 'Especialidad'
                      "
                      :loading="specialities.isLoading"
                      :disabled="!specialities.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="specialities.data"
                      item-text="name"
                      item-value="id"
                      v-model="subjects.filters.speciality_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ subjects.filters.speciality_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="subjects.headers"
            :items="filteredData"
            :search="subjects.search"
            :items-per-page="10"
            :loading="subjects.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Ver perfiles', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="goToSubjectEvaluations(item)"
                      color="cyan lighten-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-notebook-multiple
                    </v-icon>
                  </template>
                  <span>Ver perfiles</span>
                </v-tooltip>
              </template>

              <template
                v-if="
                  existInArray(
                    'Descargar listado de estudiantes',
                    currentPageActions
                  )
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlSelectAcademicOrTechnicalGroup();
                      "
                      color="teal accent-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-format-list-text
                    </v-icon>
                  </template>
                  <span>Listado de estudiantes</span>
                </v-tooltip>
              </template>

              <template
                v-if="
                  existInArray(
                    'Generar reporte de calificaciones ingresadas por un docente',
                    currentPageActions
                  )
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlDownloadEnteredScoresReport();
                      "
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-excel
                    </v-icon>
                  </template>
                  <span>Reporte de calificaciones</span>
                </v-tooltip>
              </template>
              <template
                v-if="
                  existInArray(
                    'Generar reporte de calificaciones ingresadas por un docente',
                    currentPageActions
                  )
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlSelectStageAndGroupModal();
                      "
                      color="deep-purple darken-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-progress-clock
                    </v-icon>
                  </template>
                  <span>Consolidado de notas por etapa</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <SelectAcademicOrTechnicalGroupModal
      ref="mdlSelectAcademicOrTechnicalGroup"
      :subjectTeacherId="selectedSubject.id"
    >
    </SelectAcademicOrTechnicalGroupModal>

    <DownloadEnteredScoresReport
      ref="mdlDownloadEnteredScoresReport"
      :subject="selectedSubject"
    >
    </DownloadEnteredScoresReport>

    <SelectStageAndGroupModal
      ref="mdlSelectStageAndGroupModal"
      :subject="selectedSubject"
    ></SelectStageAndGroupModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import SelectStageAndGroupModal from "@/components/modals/teacher_subjects/SelectStageAndGroupModal.vue";
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import gradeRepository from "@/repositories/gradeRepository";
import specialityRepository from "@/repositories/specialityRepository";
import SelectAcademicOrTechnicalGroupModal from "@/components/modals/teacher_subjects/SelectAcademicOrTechnicalGroupModal.vue";
import DownloadEnteredScoresReport from "@/components/modals/teacher_subjects/DownloadEnteredScoresReport.vue";

export default {
  name: "TeacherSubjects",
  title: "Mis asignaturas | GE ITR",
  components: {
    DownloadEnteredScoresReport,
    SelectAcademicOrTechnicalGroupModal,
    SelectStageAndGroupModal,
  },
  data() {
    return {
      subjects: {
        headers: [
          { text: "Asignatura", value: "name" },
          { text: "Código de la asignatura", value: "code" },
          { text: "Tipo de asignatura", value: "subject_type" },
          { text: "Grado", value: "grade" },
          { text: "Especialidad", value: "speciality" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      selectedSubject: {},
      grades: { data: [], isLoading: false },
      specialities: { data: [], isLoading: false },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis asignaturas", route: "teacher_subjects" },
    ]);
    this.loadSubjects();
    this.getGrades();
    this.getSpecialities();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Mis asignaturas");
  },
  methods: {
    selectSubject(obj) {
      // Creating a non reactive copie of data
      this.selectedSubject = { ...obj };
    },
    unselectSubject() {
      this.selectedSubject = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    loadSubjects() {
      // Si el id_user no esta setteado no se hace la petición
      if (!this.currentUserPersonalInfo.id_user) {
        return;
      }
      this.subjects.isLoading = true;
      subjectTeacherRepository
        .getSubjectsOfTeacher(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    getGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch((err) => {
          console.warn(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },
    getSpecialities() {
      this.specialities.isLoading = true;
      specialityRepository
        .getAllSpecialities()
        .then(({ data }) => {
          this.specialities.data = data;
        })
        .catch((err) => {
          console.warn(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.specialities.isLoading = false;
        });
    },
    goToSubjectEvaluations({ id, subject_id }) {
      this.$router.push({
        path: "subject_evaluations",
        query: {
          subject_teacher: id,
          subject: subject_id,
        },
      });
    },
    showMdlSelectAcademicOrTechnicalGroup() {
      this.$refs.mdlSelectAcademicOrTechnicalGroup.toggleModal();
    },
    showMdlDownloadEnteredScoresReport() {
      this.$refs.mdlDownloadEnteredScoresReport.toggleModal();
    },
    showMdlSelectStageAndGroupModal() {
      this.$refs.mdlSelectStageAndGroupModal.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    filteredData() {
      return this.filterData(this.subjects.filters, this.subjects.data);
    },
    getUserId() {
      return this.currentUserPersonalInfo.id_user;
    },
  },
  watch: {
    getUserId(newValue, oldValue) {
      // Si el id_user no tenia ningun valor se observa a cuando cambie y se  hace la peticón
      if (newValue) {
        this.loadSubjects();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
