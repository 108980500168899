<template>
  <v-col cols="12" sm="6" class="p-0">
    <v-card class="mx-auto mb-4" max-width="344" outlined>
      <v-list-item two-line>
        <v-list-item-content class="ml-2 my-2">
          <v-list-item-title class="text-body-1 mb-1">
            {{ technicalGroup.grade }}
          </v-list-item-title>
          <p class="text-h5 font-weight-bold mb-2">
            {{ technicalGroup.technicalGroup }}
          </p>
          <v-btn
            :disabled="failed"
            :href="studentListURL"
            :loading="isLoading"
            target="_blank"
            text
          >
            <v-icon class="mr-2" color="red" left>
              mdi-file-pdf-box
            </v-icon>
            Generar listado de estudiantes
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "TechnicalGroupCardEle",
  props: {
    technicalGroup: {
      type: Object,
    },
  },
  data() {
    return {
      gradeSpecialityGroupId: null,
      userId: null,
      failed: false,
      isLoading: false,
    };
  },
  beforeMount() {
    this.isLoading = true;
  },
  mounted() {
    this.encryptIds();
  },
  methods: {
    async encryptIds() {
      try {
        // Se hace la petición para encriptar el id del grupo académico
        const { data: userIdEncrypted } = await ApiService.get(
          `${this.reportsApiUrl}/version/u/${this.currentUserPersonalInfo.id_user}`
        );
        // Se hace la petición para encriptar el id del usuario
        const { data: gradeSpecialityGroupIdEncrypted } = await ApiService.get(
          `${this.reportsApiUrl}/version/u/${this.technicalGroup.id}`
        );
        // Se guardan los id's encriptados
        this.gradeSpecialityGroupId = gradeSpecialityGroupIdEncrypted;
        this.userId = userIdEncrypted;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        // Se settea la variable true para ocultar el botón
        this.isLoading = false;
        this.failed = true;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    studentListURL() {
      return `${this.reportsApiUrl}/students/grade-speciality-group/${this.gradeSpecialityGroupId}/user/${this.userId}/pdf`;
    },
    reportsApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
};
</script>
