<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar color="green accent-2" class="elevation-0 px-3">
          <span class="headline">Generar reporte de calificaciones</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <v-select
                  :label="stages.areLoading ? 'Cargando etapas..' : 'Etapa'"
                  :loading="stages.areLoading"
                  :disabled="stages.areLoading || !stages.data.length"
                  color="green accent-2"
                  outlined
                  hide-details
                  clearable
                  :items="stages.data"
                  item-text="stage.name"
                  item-value="stage_id"
                  v-model="reportParams.stage_id"
                >
                  <!-- begin::selected stage item -->
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="green accent-2" small v-if="index === 0">
                      <span>{{ item.stage.name }}</span>
                    </v-chip>
                  </template>
                  <!-- end::selected stage item -->
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cancelar</v-btn>
          <v-btn
            color="green accent-2"
            depressed
            :href="getTeacherEnteredScoresReportUrl"
            download
            :disabled="!canGenerateTeacherEnteredScoresReport"
            >Generar reporte</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import stageRepository from "@/repositories/stageRepository";

export default {
  name: "DownloadEnteredScoresReport",

  data() {
    return {
      dialog: false,
      stages: {
        areLoading: false,
        data: [],
      },
      reportParams: {},
    };
  },
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.reportParams = {};
    },

    loadStages() {
      this.stages.areLoading = true;
      stageRepository
        .stageByAcademicLevel(this.gradeId, this.specialityId)
        .then(({ data }) => {
          this.stages.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          this.stages.areLoading = false;
        });
    },
  },
  computed: {
    gradeId() {
      if (!this.subject) return;
      return this.subject.grade_id;
    },
    specialityId() {
      if (!this.subject) return;
      return this.subject.speciality_id;
    },
    reportsApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
    getTeacherEnteredScoresReportUrl() {
      if (!this.canGenerateTeacherEnteredScoresReport) {
        return "";
      }

      let url = `${this.reportsApiUrl}/scores/subject-teacher/${this.subject?.id}/stage/${this.reportParams?.stage_id}`;
      return url;
    },
    canGenerateTeacherEnteredScoresReport() {
      return !!this.reportParams.stage_id;
    },
  },
  watch: {
    subject(newValue, oldValue) {
      if (newValue) {
        this.loadStages();
      }
    },
  },
};
</script>
