<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="768"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="deep-purple" class="elevation-0 px-3">
            <span class="headline"
              >Listado de estudiantes por grupos académicos o técnicos</span
            >
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-container v-if="isLoadingGroups" style="height: 200px;">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Cargando grupos académicos y técnicos
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="deep-purple darken-3"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-else-if="
                !isLoadingGroups &&
                  technicalGroups.length == 0 &&
                  academicGroups.length == 0
              "
              style="height: 200px;"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  No hay ningún grupo académico o técnico
                </v-col>
              </v-row>
            </v-container>
            <div v-else class="card-body">
              <v-container v-if="academicGroups.length > 0">
                <v-row>
                  <v-col tag="h6">Grupos académicos</v-col>
                </v-row>
                <v-row>
                  <AcademicGroupCardEle
                    v-for="(academicGroup, index) in academicGroups"
                    :key="`a-g-${index}`"
                    :academicGroup="academicGroup"
                  >
                  </AcademicGroupCardEle>
                </v-row>
              </v-container>
              <v-container v-if="technicalGroups.length > 0">
                <v-row>
                  <v-col tag="h6">Grupos técnicos</v-col>
                </v-row>
                <v-row>
                  <TechnicalGroupCardEle
                    v-for="(technicalGroup, index) in technicalGroups"
                    :key="`t-g-${index}`"
                    :technicalGroup="technicalGroup"
                  >
                  </TechnicalGroupCardEle>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import AcademicGroupCardEle from "@/components/elements/teacher_subjects/AcademicGroupCardEle.vue";
import TechnicalGroupCardEle from "@/components/elements/teacher_subjects/TechnicalGroupCardEle.vue";

export default {
  name: "SelectAcademicGroupModal",
  components: {
    AcademicGroupCardEle,
    TechnicalGroupCardEle,
  },
  data() {
    return {
      isLoading: false,
      isLoadingGroups: true,
      dialog: false,
      academicGroups: [],
      technicalGroups: [],
      gradeSections: {},
    };
  },
  props: {
    subjectTeacherId: {
      type: Number,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
    },

    getGroups() {
      this.isLoadingGroups = true;
      subjectTeacherRepository
        .getSubjectTeacherWithGroups(this.subjectTeacherId)
        .then(({ data }) => {
          // Se recorren los teacher_groups
          data.teacher_groups.forEach((element) => {
            // Se verifica si tiene esa key en el objecto groupable para saber si es grupo académico y si no, es técnico
            if ("section_group_id" in element.groupable) {
              this.academicGroups.push({
                id: element.teacher_groupable_id,
                sectionGroup: `Sección ${element.groupable.section_group.section.name}-${element.groupable.section_group.group.name}`,
                grade: element.groupable.grades.name,
              });
            } else if ("speciality_id" in element.groupable) {
              this.technicalGroups.push({
                id: element.teacher_groupable_id,
                technicalGroup: `${element.groupable.speciality.name} ${element.groupable.groups.name}`,
                grade: element.groupable.grades.name,
              });
            }
          });
        })
        .catch(() => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingGroups = false;
        });
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.academicGroups = [];
        this.technicalGroups = [];
        this.getGroups();
      }
    },
  },
};
</script>
