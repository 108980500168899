<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="550"
      persistent
    >
      <v-form>
        <v-card>
          <v-toolbar color="deep-purple darken-1" class="elevation-0 px-3">
            <span class="headline white--text">Seleccionar grupo y etapa</span>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <!-- stages -->
              <v-row>
                <v-col col="12" sm="12" class="pb-0">
                  <v-select
                    :label="isLoadingStages ? 'Cargando etapas..' : 'Etapa'"
                    :loading="isLoadingStages"
                    :disabled="isLoadingStages"
                    color="deep-purple darken-1"
                    outlined
                    hide-details
                    clearable
                    :items="stages"
                    item-text="stage.name"
                    item-value="stage"
                    return-object
                    v-model="reportParams.stage"
                  >
                    <!-- begin::selected stage item -->
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        color="deep-purple darken-1"
                        class="white--text"
                        small
                        v-if="index === 0"
                      >
                        <span>{{ item.stage.name }}</span>
                      </v-chip>
                    </template>
                    <!-- end::selected stage item -->
                  </v-select>
                </v-col>
              </v-row>
              <!-- Groups list -->
              <v-row>
                <v-col col="12" sm="12" class="pb-0">
                  <v-select
                    outlined
                    clearable
                    color="deep-purple darken-1"
                    :label="
                      isLoadingGroups ? 'Cargando grupos..' : groupTypeLabel
                    "
                    :loading="isLoadingGroups"
                    :disabled="isLoadingGroups"
                    :items="groupsFormatted"
                    item-text="group"
                    item-value="id"
                    return-object
                    v-model="reportParams.group"
                  >
                    <!-- begin::selected stage item -->
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        color="deep-purple darken-1 white--text"
                        small
                        v-if="index === 0"
                      >
                        <span>{{ item.group }}</span>
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <span>
                            <strong class="ml-1">{{ data.item.group }}</strong>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <!-- end::selected stage item -->
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn
              text
              outlined
              :disabled="isLoading"
              class="mr-1"
              @click="toggleModal()"
              >Cerrar</v-btn
            >
            <v-btn
              :disabled="!canGenerateScores"
              color="deep-purple darken-1"
              class="white--text"
              :loading="isLoading"
              @click="getScores()"
              depressed
              >Cargar notas</v-btn
            >
          </v-card-actions>
          <AcademicStageAverageScores
            ref="mdlAcademicStageAverageScores"
            :scores="scores"
            :subject="subject"
            :selectedGroup="selectedGroup"
            :selectedStage="selectedStage"
          ></AcademicStageAverageScores>
          <TechnicalStageAverageScores
            ref="mdlTechnicalStageAverageScores"
            :scores="scores"
            :subject="subject"
            :selectedGroup="selectedGroup"
            :selectedStage="selectedStage"
          ></TechnicalStageAverageScores>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import stageRepository from "@/repositories/stageRepository";
import AcademicStageAverageScores from "./AcademicStageAverageScores.vue";
import TechnicalStageAverageScores from "./TechnicalStageAverageScores.vue";

export default {
  name: "SelectStageAndGroupModal",
  components: {
    AcademicStageAverageScores,
    TechnicalStageAverageScores,
  },
  data() {
    return {
      isLoadingGroups: false,
      isLoadingStages: false,
      isLoading: false,
      dialog: false,
      groupsAcademicLevelId: null,
      groupsFormatted: [],
      scores: [],
      stages: [],
      reportParams: {},
    };
  },
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.cleanModal();
    },

    cleanModal() {
      this.reportParams = {};
      this.stages = [];
      this.groupsFormatted = [];
    },
    getGroups() {
      this.isLoadingGroups = true;
      subjectTeacherRepository
        .getSubjectTeacherWithGroups(this.subject.id)
        .then(({ data }) => {
          // Se recorren los teacher_groups

          this.groupsAcademicLevelId =
            data.teacher_groups[0].groupable.grades.academic_level_id;

          data.teacher_groups.forEach((element) => {
            // Se verifica si existe la propiedad en el objecto groupable para saber si es grupo académico y si no, es técnico
            let tempObject;

            if ("section_group_id" in element.groupable) {
              tempObject = {
                id: element.teacher_groupable_id,
                group: `${element.groupable.grades.name} - Sección ${element.groupable.section_group.section.name}-${element.groupable.section_group.group.name}`,
                academic_level: element.groupable.grades.academic_level_id,
              };
            } else {
              tempObject = {
                id: element.teacher_groupable_id,
                group: `${element.groupable.grades.name} - ${element.groupable.speciality.name} ${element.groupable.groups.name}`,
                academic_level: element.groupable.grades.academic_level_id,
              };
            }

            this.groupsFormatted.push(tempObject);
          });
        })
        .catch(() => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingGroups = false;
        });
    },
    loadStages() {
      this.isLoadingStages = true;
      stageRepository
        .stageByAcademicLevel(this.gradeId, this.specialityId)
        .then(({ data }) => {
          let rawData = [...data];

          if (this.subject?.subject_type_id == 1) {
            rawData.forEach((item) => {
              if (item.stage?.stage_type_id == 3) {
                this.stages.push(item);
              }
            });
            return;
          }

          if (this.subject?.subject_type_id == 2) {
            rawData.forEach((item) => {
              if (item.stage?.stage_type_id < 3) {
                this.stages.push(item);
              }
            });
            return;
          }

          this.stages = rawData;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          this.isLoadingStages = false;
        });
    },
    getScores() {
      if (
        !this.subject.has_technical_groups ||
        this.subject.subject_type_id == 2
      ) {
        this.fetchAcademicStageAverageScores();
        return;
      }

      if (this.subject.subject_type_id == 1) {
        this.fetchTechnicalStageAverageScores();
        return;
      }

      this.fireToast({
        icon: "error",
        title: "Hubo un error al intentar obtener las notas",
      });
    },

    fetchAcademicStageAverageScores() {
      this.isLoading = true;
      subjectTeacherRepository
        .getStageAverageScoreAcademic(
          this.reportParams.group.id,
          this.reportParams.stage.stage_id,
          this.subject.subject_id
        )
        .then(({ data }) => {
          this.scores = data;
          this.showMdlAcademicStageAverageScores();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las notas.",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchTechnicalStageAverageScores() {
      this.isLoading = true;
      subjectTeacherRepository
        .getStageAverageScoreTecnical(
          this.reportParams.group.id,
          this.reportParams.stage.stage_id,
          this.subject.subject_id
        )
        .then(({ data }) => {
          this.scores = data;
          this.showMdlTechnicalStageAverageScores();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las notas",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showMdlAcademicStageAverageScores() {
      this.$refs.mdlAcademicStageAverageScores.toggleModal();
    },
    showMdlTechnicalStageAverageScores() {
      this.$refs.mdlTechnicalStageAverageScores.toggleModal();
    },
  },
  computed: {
    canGenerateScores() {
      return !!this.reportParams.group && !!this.reportParams.stage;
    },
    gradeId() {
      if (!this.subject) return;
      return this.subject.grade_id;
    },
    specialityId() {
      if (!this.subject) return;
      return this.subject.speciality_id;
    },
    groupTypeLabel() {
      if (this.groupsAcademicLevelId == 1) {
        return "Grupo académico";
      }
      return "Grupo técnico";
    },
    selectedGroup() {
      return this.reportParams.group;
    },
    selectedStage() {
      return this.reportParams.stage?.stage;
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.getGroups();
        this.loadStages();
      }
    },
  },
};
</script>
